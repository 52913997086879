<template>
  <AppSidebar fixed>
    <SidebarHeader/>
    <SidebarForm/>
    <SidebarNav :navItems="nav"></SidebarNav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </AppSidebar>
</template>
<script>
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@coreui/vue'

export default {
  name: 'Sidebar',
  components: {
    AppSidebar,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    nav () {
      let nav =[
        {
          title: true,
          name: 'Clients',
          class: '',
          wrapper: {
            element: '',
            attributes: {}
          }
        },
        {
          name: 'Search',
          url: '/clients/search',
          icon: 'icon-magnifier',
          badge: {
            variant: 'primary'
          }
        }
      ]

      if (this.$can('create', 'client')) {
        nav.push({
          name: 'Add New Client',
          url: '/clients/search?add',
          icon: 'icon-plus'
        })
      }
      return nav
    }
  }
}
</script>
